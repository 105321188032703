import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(private auth: AuthService){}

  loginWithRedrict(){
    this.auth.loginWithRedirect();
  }

   // Function to open HelpScout Beacon
   openHelpScout(): void {
    if (window['Beacon']) {
      window['Beacon']('toggle');
    } else {
      console.error("HelpScout Beacon is not initialized");
    }
  }
}

<footer class="footer">
    <div class="content-footer">
      <div class="footer-content">
        <span>
            <img src="../../assets/img/footer-icon.png" alt="Copyright" class="footer-copyright-image"/>
            ©{{ currentYear }} Ocado Retail Ltd. All Rights Reserved</span>
        <div class="footer-links">
          <a href='pages/privacy' target="_blank" class="footer-link" >Privacy</a>        
          <a href='pages/EULA' target="_blank" class="footer-link">EULA</a>
          <a href='pages/accessibility' target="_blank" class="footer-link">Accessibility</a>
        </div>
      </div>
    </div>
  </footer>
  
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ApplicationService } from '../services/application/application.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrl: './myprofile.component.scss'
})
export class MyprofileComponent implements OnInit {
  user: any;
  roleData: any[] = [];

  constructor(private auth: AuthService, private applicationService: ApplicationService){}

  ngOnInit(): void {
    this.auth.user$.subscribe(
      (profile) => {
        this.user = profile;
        // console.log('Profile', this.user);
      }
    )

    this.auth.idTokenClaims$.subscribe(idToken => {
      const userApplications = idToken?.['applications']?.split(' ') || [];
      this.auth.getAccessTokenSilently().subscribe(token => {
        if (token) {          
          const decodedToken: any = jwtDecode(token);
          const scopeRoles = decodedToken.scope.split(' ').filter((scope: string) => {
            return !['openid', 'profile', 'email', 'offline_access'].includes(scope);
          });

          const roleMap: { [key: string]: string[] } = {};

          // Build the role map from the scope roles
          scopeRoles.forEach((scope: string) => {
            const [appId, ...roleParts] = scope.split(':');
            const role = roleParts.join(':'); // Combine the remaining parts as the role
            if (!roleMap[appId]) {
              roleMap[appId] = [];
            }
            roleMap[appId].push(role);
          });

          // Fetch the applications data from the service
          this.applicationService.getApplications().subscribe(apps => {
            this.roleData = userApplications.map((appId: any) => {
              const appData = apps.find((app: { application_id: any; }) => app.application_id === appId);
              return {
                application: appData?.name || appId,
                role: roleMap[appId]?.join(', ') || 'Unknown Role',
                description: appData?.description || 'No description available',
                permission: true 
              };
            });
          });
        } else {
          console.log('No  access token found');
        }
      });
    });

  }

}

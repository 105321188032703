<div class="background-image">
    <div class="login-box">
       <div class="ocado-logo">
      <img src="../assets/img/ocado-logo-latest.png" alt="ocado logo" />
    </div>
      <h1>Welcome to OcadoHub!</h1>
      <p>
        As a supplier this is your one-stop-shop to working with Ocado
      </p>
      <button type="button" (click)="loginWithRedrict()">
        <span>Login</span>
      </button>
      <p style="margin-top: 20px;">
        If you experience any technical difficulties logging in please <br>
        contact <a href="javascript:void(0);" (click)="openHelpScout()">technical support.</a>
      </p>
    </div>
  </div>
  
<ejs-appbar class="app-bar">
  <div class="d-flex menu-content">
    <a [href]="rootUrl" target="_self">
      <img
        src="../assets/img/Ocado-logo-new.png"
        alt="ocado-logo"
        style="margin-left: 15px"
      />
    </a>

    <div class="action-items"  *ngIf="showIfloogedIn">
      <div class="d-flex user-account spacing">
        <img
          class="avatar"
          [src]="
            user?.picture ? user.picture : '../../assets/img/avatar-icon.png'
          "
          alt="image"
        />
        <div class="user-name">
          <span class="row name">
            {{
              user?.name.length > 20
                ? (user?.name | slice : 0 : 20) + "..."
                : user?.name
            }}
          </span>
          <ng-container *ngIf="user?.company_name?.length > 30; else notooltip">
            <ejs-tooltip content="{{ user?.company_name }}">
              <span class="row company">
                {{ user?.company_name | slice : 0 : 30 }}...
              </span>
            </ejs-tooltip>
          </ng-container>
          <ng-template #notooltip>
            <span class="row company">
              {{ user?.company_name }}
            </span>
          </ng-template>
        </div>
      </div>
      <span class="pipe">|</span>
      <span class="log-out spacing" (click)="logOut()">Logout</span>
      <!-- <span id="content" class="waffle">
        <a [href]="rootUrl" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 4H8V8H4V4ZM10 4H14V8H10V4ZM16 4H20V8H16V4ZM4 10H8V14H4V10ZM10 10H14V14H10V10ZM16 10H20V14H16V10ZM4 16H8V20H4V16ZM10 16H14V20H10V16ZM16 16H20V20H16V16Z"
              fill="#441E62"
            />
          </svg>
        </a>
      </span> -->
      <ejs-tooltip
        #tooltip
        target="#content"
        cssClass="tooltip-dialogbox"
        opensOn="Click"
        (afterOpen)="waffleOpen()"
        (afterClose)="waffleOpen()"
        (created)="created()"
      >
        <span id="content" class="waffle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            [ngClass]="waffleTooltip ? 'waffle-active' : ''"
          >
            <path
              d="M4 4H8V8H4V4ZM10 4H14V8H10V4ZM16 4H20V8H16V4ZM4 10H8V14H4V10ZM10 10H14V14H10V10ZM16 10H20V14H16V10ZM4 16H8V20H4V16ZM10 16H14V20H10V16ZM16 16H20V20H16V16Z"
              fill="#441E62"
            />
          </svg>
        </span>
        <ng-template #content>
          <div class="demo-content">
            <div class="explore-section">
              <div class="explore-text">Explore!</div>
              <span class="inner-content"
                >You can now explore other platforms</span
              >
              <hr class="custom-line" />
              <div class="app-content">
                <div
                  class="e-card"
                  *ngFor="let app of apps"
                  [ngClass]="{
                    'inactive-app':
                      !userApplications.includes(app.application_id) ||
                      (!isStaff && app.staff_only)
                  }"
                  (click)="
                    userApplications.includes(app.application_id) &&
                    (isStaff || !app.staff_only)
                      ? openApp(app.url)
                      : $event.stopPropagation()
                  "
                >
                  <div class="e-card-content">
                    <img [src]="app.icon" alt="icon" />
                    <br />
                    <span class="app-title">{{ app.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ejs-tooltip>
    </div>
  </div>
</ejs-appbar>
<div class="row d-flex menu-bar" *ngIf="showIfloogedIn">
  <ejs-menu
    #menu
    [items]="menuItems"
    (select)="onSelect($event)"
    (created)="onCreated($event)"
  >
    <ng-template #menutemplate let-menuItems>
      <a [routerLink]="menuItems.path">
        {{ menuItems.text }}
      </a>
    </ng-template>
  </ejs-menu>
</div>
<router-outlet></router-outlet>
<app-footer *ngIf="showIfloogedIn"></app-footer>

import { ICache, Cacheable } from '@auth0/auth0-angular';
import { MaybePromise } from '@auth0/auth0-spa-js';

// Custom Session Storage Cache implementing ICache
export class SessionStorageCache implements ICache {
    set<T = Cacheable>(key: string, entry: T): MaybePromise<void> {
        window.sessionStorage.setItem(key, JSON.stringify(entry));
    }
    get<T = Cacheable>(key: string): MaybePromise<T | undefined> {
        const cachedItem = window.sessionStorage.getItem(key);
        return cachedItem ? JSON.parse(cachedItem) : null;
    }
    remove(key: string): MaybePromise<void> {
        sessionStorage.removeItem(key);
    }
    allKeys?(): MaybePromise<string[]> {
        return Object.keys(window.sessionStorage).filter(key =>
            key.startsWith('@@auth0spajs@@')
          );
    }

}



<ng-container *ngIf="isPublicPage; else appContent">
    <app-layout></app-layout>
</ng-container>
  
  <ng-template #appContent>
    <ng-container *ngIf="(auth.isLoading$ | async) === false; else loading">
      <ng-container *ngIf="auth.isAuthenticated$ | async; else login">
        <div class="control-section">
          <app-layout></app-layout>
        </div>
      </ng-container>
    </ng-container>
  
    <ng-template #login>
      <app-login></app-login>
    </ng-template>
  
    <ng-template #loading>
      <app-loading></app-loading>
    </ng-template>
  </ng-template>
  
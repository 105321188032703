import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'supplier-hub';

  constructor( public auth: AuthService, private router: Router){

  }

  user: any;
  isPublicPage: boolean = false;

  ngOnInit(): void {
    this.isPublicPage = false;
    // this.auth.isAuthenticated$.subscribe(isAuthenticated => {
    //   if (!isAuthenticated) {
    //     this.router.navigate(['/']);
    //   } 
    // });
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      const currentRoute = this.router.url;
      this.isPublicPage = currentRoute.startsWith('/pages');

      if (isAuthenticated && currentRoute === '/welcome') {
        // Redirect logged-in users away from the welcome page
        this.router.navigate(['/']);
      } else if (!isAuthenticated && !this.isPublicPage && currentRoute !== '/welcome') {
        // Redirect unauthenticated users to welcome page
        this.router.navigate(['/welcome']);
      }
    });


       this.auth.user$.subscribe((userDetails: any) => {
        this.user = userDetails;
        this.identifyUserWithHelpScout(this.user);
      });
    }
  
    identifyUserWithHelpScout(user: any): void {
      if ((window as any)['identifyUserWithHelpScout']) {
        (window as any)['identifyUserWithHelpScout'](user);
      }
    }

}

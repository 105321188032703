import { Component, OnInit } from '@angular/core';
import { Application } from '../models/application.model';
import { ApplicationService } from '../services/application/application.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-myapps',
  templateUrl: './myapps.component.html',
  styleUrl: './myapps.component.scss'
})
export class MyappsComponent implements OnInit{

  // activeApplication: Application[] = [];
  // inactiveApplication: Application[] = [];

  apps: Application[] = [];
  userApplications: string[] = [];
  isStaff: boolean = false;

  constructor( private applicationService: ApplicationService, private auth: AuthService) {
    
  }
 
  
  ngOnInit(): void {
    this.loadApplications();
  }

  loadApplications(): void {
    this.auth.idTokenClaims$.subscribe(idToken => {
       this.userApplications = idToken?.['applications']?.split(' ') || [];
       this.isStaff = idToken?.['is_staff'] || false;

      this.applicationService.getApplications().subscribe(
        (apps: Application[]) => {
          // Replace the icon for each application by removing "waf" from the filename
          const updatedApps = apps.map(app => {
            const updatedIcon = app.icon.replace('-waf', '');
            return { ...app, icon: updatedIcon };
          });

          // Filter out the 'suphub' application
          const filteredApps = updatedApps.filter(app => app.application_id !== 'suphub');

          //remove active=false app and non staff access app
          this.apps = filteredApps.filter(app => app.active && (!(app.staff_only) || this.isStaff));
          
          //filter apps based on ID token
          // this.activeApplication = activeApps.filter(app =>
          // //  userApplications.includes(app.application_id) && (!(app.staff_only) || isStaff)
          // userApplications.includes(app.application_id) 

          // );

          // this.inactiveApplication = activeApps.filter(app =>
          //  !userApplications.includes(app.application_id)
          // );
        },
        (error) => {
          console.error('Error fetching applications:', error);
        }
      );
    });
  }
  
  openApp(url: string): void {
    window.open(url, '_blank'); 
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyappsComponent } from './myapps/myapps.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
   { path: 'welcome', component: LoginComponent},
  //  { path: 'welcome', redirectTo: 'welcome', pathMatch: 'full'},
   { path: 'pages/:type', component:  PrivacyComponent},
   { path: '', component: MyappsComponent, canActivate: [AuthGuard]}, 
   { path: 'profile', component: MyprofileComponent, canActivate: [AuthGuard]},
   { path: '**', redirectTo: '', canActivate: [AuthGuard]}
     
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="apps-title">
    <span>My Profile</span>
  </div>
  <div class="details-container">
    <div class="row gx-0">
      <span class="acc-details">Account Details</span>
    </div>
    <div class="row gx-0" style="max-width: 86%">
      <div class="col-3" style="margin-right: 16px">
        <label for="orgName" class="form-label">Organisation Name</label>
        <input
          type="text"
          id="orgName"
          class="form-control"
          [value]="user?.company_name"
          disabled
        />
      </div>
      <div class="col-3">
        <label for="orgId" class="form-label">Organisation ID</label>
        <input
          type="text"
          id="orgId"
          class="form-control"
          [value]="user?.company_id"
          disabled
        />
      </div>
    </div>
  </div>
  
  <div class="my-details-container">
    <div class="row gx-0">
      <span class="my-details">My Details</span>
    </div>
    <div class="row gx-0" style="max-width: 86%">
      <div class="col-3" style="margin-right: 16px">
        <label for="firstName" class="form-label">First Name</label>
        <input
          type="text"
          id="firstName"
          class="form-control"
          [value]="user?.firstname"
          disabled
        />
      </div>
      <div class="col-3" style="margin-right: 16px">
        <label for="lastName" class="form-label">Last Name</label>
        <input
          type="text"
          id="lastName"
          class="form-control"
          [value]="user?.lastname"
          disabled
        />
      </div>
      <div class="col-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="text"
          id="email"
          class="form-control"
          [value]="user?.email"
          disabled
        />
      </div>
    </div>
  </div>
  
  <div class="role-details">
    <div class="row gx-0">
      <span class="role-title">Role</span>
    </div>
    <table>
      <thead>
        <tr>
          <th>Application</th>
          <th>Role</th>
          <th>Description</th>
          <th>Permission</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let roleData of roleData">
          <td>{{ roleData.application }}</td>
          <td>{{ roleData.role }}</td>
          <td>{{ roleData.description }}</td>
          <td>
            <input class="form-check-input" type="checkbox" [checked]="roleData.permission" disabled />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  

<div class="row gx-0 apps-title">
  <span>Apps</span>
</div>
<div class="my-apps">
  <div class="row gx-0">
    <span class="acc-details">My Apps</span>
  </div>
  <div class="access-app">
    <div class="e-card"
    *ngFor="let app of apps"
    [ngClass]="{
      'inactive-app':
        !userApplications.includes(app.application_id) ||
        (!isStaff && app.staff_only)
    }"
    (click)="
      userApplications.includes(app.application_id) &&
      (isStaff || !app.staff_only)
        ? openApp(app.url)
        : $event.stopPropagation()
    "
    >
    <div class="e-card-content">
      <div
        class="avatar-container"
        [ngStyle]="{ 'background-color': app.colour }"
      >
        <img class="avatar-icon" [src]="app.icon" alt="icon" />
      </div>
      <br />
      <span class="app-title">{{ app.name }}</span
      ><br />
      <span class="app-desc">{{ app.description }}</span>
    </div>
    <!-- "Coming Soon" Overlay -->
    <div class="coming-soon" *ngIf="!userApplications.includes(app.application_id) || (!isStaff && app.staff_only)">
      Coming Soon!
    </div>
    </div>
  </div>
</div> 
<!-- <div class="my-apps">
  <div class="row gx-0">
    <span class="acc-details">My Apps</span>
  </div>
  <div class="access-app">
      <div class="e-card" *ngFor="let app of activeApplication" (click)="openApp(app.url)">
        <div class="e-card-content">
          <div
            class="avatar-container"
            [ngStyle]="{ 'background-color': app.colour }"
          >
            <img class="avatar-icon" [src]="app.icon" alt="icon" />
          </div>
          <br />
          <span class="app-title">{{ app.name }}</span
          ><br />
          <span class="app-desc">{{ app.description }}</span>
        </div>
      </div> 
  </div>
</div> -->
<!-- <div class="my-apps">
  <div class="row gx-0">
    <span class="acc-details">No Access</span>
  </div>
  <div class="noaccess-app">
      <div class="e-card" *ngFor="let app of inactiveApplication">
        <div class="e-card-content">
          <div
            class="avatar-container"
            [ngStyle]="{ 'background-color': app.colour }"
          >
            <img class="avatar-icon" [src]="app.icon" alt="icon" />
          </div>
          <br />
          <span class="app-title">{{ app.name }}</span
          ><br />
          <span class="app-desc">{{ app.description }}</span>
        </div>
      </div>
  </div>
</div> -->

import { NgModule } from '@angular/core';
import { AppBarModule, MenuModule } from "@syncfusion/ej2-angular-navigations";
import { TooltipModule } from '@syncfusion/ej2-angular-popups';

const modules = [
  AppBarModule,
  MenuModule,
  TooltipModule, 
];



@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class SyncfusionModule { }

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MenuComponent } from '@syncfusion/ej2-angular-navigations';
import { Application } from '../models/application.model';
import { ApplicationService } from '../services/application/application.service';
import { TooltipComponent } from '@syncfusion/ej2-angular-popups';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  @ViewChild('menu') public menu!: MenuComponent;
  @ViewChild('tooltip')
  public control: TooltipComponent | any;
  public selectedMenuItemIndex: number = 0;

  rootUrl: string = environment.redirect_uri;

  constructor(
    private router: Router,
    private auth: AuthService,
    private applicationService: ApplicationService
  ) {}

  user: any;
  waffleTooltip: boolean = false;

  apps: Application[] = [];
  userApplications: string[] = [];
  isStaff: boolean = false;


  showIfloogedIn: boolean = true;  // This flag controls the visibility of the menuTab, footer and appbar

  public menuItems: any[] = [
    { text: 'Apps', path: '/' },
    { text: 'My Profile', path: '/profile' },
  ];

  ngOnInit(): void {
    this.auth.user$.subscribe((profile) => {
      this.user = profile;
      // console.log('Profile', this.user);
    });
   
      //   // Listen to router events and toggle menu visibility based on the route
      //   this.router.events
      //   .pipe(filter((event: any) => event instanceof NavigationEnd))
      //   .subscribe((event: NavigationEnd) => {
      //     console.log('NavigationEnd event detected:', event.urlAfterRedirects);

      //     // Hide menu on specific routes
      //     const hiddenRoutes = ['/pages/privacy', '/pages/accessibility', '/pages/EULA'];  // Add any other routes here
      //     this.showMenu = !hiddenRoutes.some(route => event.urlAfterRedirects.includes(route));
  
      //     // Log the value of showMenu for debugging
      //     console.log('Menu visibility:', this.showMenu);
      //   }
      // );

        // Hide menu if on public pages
      this.auth.isAuthenticated$.subscribe(isAuthenticated => {
        this.showIfloogedIn = isAuthenticated; // Show the menu only if the user is authenticated
      });


    this.loadApplications();
  }

  logOut(): any {
    this.auth.logout();
  }

  onSelect(eventArgs: any): void {

    // before footer link implemented logic
    // this.menu.element.children[this.selectedMenuItemIndex].classList.remove(
    //   'menu-active'
    // );

      //after footer link logic implemented
      // opening footer link then working apps and profile tabs
  if (this.menu.element && this.menu.element.children[this.selectedMenuItemIndex]) {
    this.menu.element.children[this.selectedMenuItemIndex].classList.remove('menu-active');
  }


    if (eventArgs.item.path) {
      this.router.navigate([eventArgs.item.path]);
      this.selectedMenuItemIndex = this.menuItems.findIndex(
        (item) => item.path === eventArgs.item.path
      );
      this.menu.element.children[this.selectedMenuItemIndex].classList.add(
        'menu-active'
      );
    }
  }

  onCreated(eventArgs: any) {
    // this.selectMenuItem('/');
   this.selectMenuItem(window.location.pathname);

  }

  selectMenuItem(path: string): void {
    this.router.navigate([path]);
    this.selectedMenuItemIndex = this.menuItems.findIndex(
      (item) => item.path === path
    );
    if (this.menu.element.children[this.selectedMenuItemIndex]) {
      this.menu.element.children[this.selectedMenuItemIndex].classList.add(
        'menu-active'
      );
    }
  }

  waffleOpen() {
    this.waffleTooltip = !this.waffleTooltip;
  }

  loadApplications(): void {
    this.auth.idTokenClaims$.subscribe(idToken => {
       this.userApplications = idToken?.['applications']?.split(' ') || [];
       this.isStaff = idToken?.['is_staff'] || false;

    this.applicationService
      .getApplications()
      .subscribe((data: Application[]) => {
        this.apps = data.filter(
        app => app.active && (!(app.staff_only) || this.isStaff)
        );
      });
  });
  }

  openApp(url: string): void {
    window.open(url, '_blank');
  }

  //close waffle screen on outside click
  public created() {
    if (document.getElementsByClassName('control-section')) {
      document
        .getElementsByClassName('control-section')[0]
        .addEventListener('click', this.onClick.bind(this));
    }
  }

  public onClick(args: any) {
    if (
      args &&
      !args.target.parentNode.parentNode.classList.contains('e-tooltip') &&
      !args.target.parentNode.parentNode.classList.contains('waffle')
    ) {
      if (
        this.control &&
        document.getElementsByClassName('e-tooltip-wrap').length > 0
      ) {
        this.control.close();
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent implements OnInit {

  markdownPath: string = '';

  constructor(private route: ActivatedRoute){}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const type = params['type'];

         // Load the corresponding markdown file
         if (type === 'privacy') {
          this.markdownPath = '../../assets/documents/compliance.md';  
        } else if (type === 'accessibility') {
          this.markdownPath = '../../assets/documents/accessibility.md';  
        } else if (type === 'EULA') {
          this.markdownPath = '../../assets/documents/EULA.md';  
        }
    })
  }

}
